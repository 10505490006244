.msg-inputs{
    width: 50%;
}

.msg-modal-msg{
    text-align: center;
    color: #1565C0;
}

.msg-modal-btn{
    width: 100%;
}